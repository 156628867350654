import Glide from '@glidejs/glide'

const iconCarousels = document.querySelectorAll(".icon-carousel__inner")

const iconCarouselsArray = Array.from(iconCarousels) || []

if(iconCarousels){

  iconCarouselsArray.forEach(item => {

    var glide = new Glide(item, {
      type: 'slider',
      perView: 3,
      gap: 60,
      rewind: false,
      breakpoints: {
        992: {
          perView: 2.5,
          gap: 30,
        },
        600: {
          perView: 1.7,
          gap: 30,
        },
        450: {
          perView: 1.2,
          gap: 30,
        },
      }
    })

    const icons = item.querySelectorAll(".icon-carousel__item");
    const iconCount = Array.from(icons).length;
    if (iconCount <= glide.settings.perView) {
      const leftButton = item.querySelector('.icon-carousel__arrow-left');
      const rightButton = item.querySelector('.icon-carousel__arrow-right');
      leftButton.classList.add('hidden');
      rightButton.classList.add('hidden');
      glide.update({
        swipeThreshold: false, 
        dragThreshold: false,
      })
    }
    
    glide.mount()
  
    const carouselRange = item.querySelector(".icon-carousel__range") as HTMLInputElement
  
    carouselRange.addEventListener("input", e => {
        setTimeout(() => {
          glide.go(`=${carouselRange.value}`)
        }, 500);
    })
  
    glide.on('move.after', () =>{
      const currentIndex = glide.index;
      carouselRange.value = currentIndex
      const rightButton = item.querySelector('.icon-carousel__arrow-right');
      if(currentIndex > iconCount -4) {
        rightButton.classList.add('glide__arrow--disabled');
      } else if (currentIndex < iconCount -3) {
        rightButton.classList.remove('glide__arrow--disabled');
      }
    })


  })
}

if(iconCarousels){
  iconCarouselsArray.forEach(item => {
    const slides = item.querySelectorAll(".icon-carousel__item")
    const carouselRange = item.querySelector(".icon-carousel__range") as HTMLInputElement
    
    const leftButton = item.querySelector('.icon-carousel__arrow-left');
    const rightButton = item.querySelector('.icon-carousel__arrow-right');

    if (leftButton.classList.contains('glide__arrow--disabled') && rightButton.classList.contains('glide__arrow--disabled')) {
        leftButton.classList.add("hidden");
        rightButton.classList.add("hidden");
    }
  
    setRangeMax(slides, carouselRange)
  })
}

function setRangeMax(arr, input) {
  const slidesLength = Array.from(arr).length
  const rangeMax = (slidesLength - 3).toString()
  input.max = rangeMax

  setRangeThumbWidth(rangeMax, input)
}

function setRangeThumbWidth(max, input) {
  const width = 200 / +max
  input.style.setProperty('--thumb-size', width.toString() + "px");
  
  if(max === "1"){
    input.style.setProperty('--thumb-size', "100px");
  }
}