import Glide from '@glidejs/glide'

const pageCardCarousels = document.querySelectorAll(".page-carousel__inner")

const pageCardCarouselsArray = Array.from(pageCardCarousels) || []

if(pageCardCarousels){

  pageCardCarouselsArray.forEach(item => {
    var glide = new Glide(item, {
      type: 'slider',
      perView: 3,
      gap: 60,
      rewind: false,
      bound: true,
      breakpoints: {
        992: {
          perView: 2.5,
          gap: 30,
        },
        600: {
          perView: 1.7,
          gap: 30,
        },
        450: {
          perView: 1.2,
          gap: 30,
        },
      }
    })

    const pages = item.querySelectorAll(".page-carousel__item");
    const pageCount = Array.from(pages).length;
    if (pageCount <= glide.settings.perView) {
      const leftButton = item.querySelector('.page-carousel__arrow-left');
      const rightButton = item.querySelector('.page-carousel__arrow-right');
      leftButton.classList.add('hidden');
      rightButton.classList.add('hidden');
      glide.update({
        swipeThreshold: false, 
        dragThreshold: false,
      })
    }

    glide.mount()
  
    const carouselRange = item.querySelector(".page-carousel__range") as HTMLInputElement
  
    carouselRange.addEventListener("input", e => {
        setTimeout(() => {
          glide.go(`=${carouselRange.value}`)
        }, 500);
    })
  
    glide.on('move.after', () =>{
      const currentIndex = glide.index;
      carouselRange.value = currentIndex
    })
  })
}

if(pageCardCarousels){
  pageCardCarouselsArray.forEach(item => {
    const slides = item.querySelectorAll(".page-carousel__item")
    const carouselRange = item.querySelector(".page-carousel__range") as HTMLInputElement

    const leftButton = item.querySelector('.page-carousel__arrow-left');
    const rightButton = item.querySelector('.page-carousel__arrow-right');

    if (leftButton.classList.contains('glide__arrow--disabled') && rightButton.classList.contains('glide__arrow--disabled')) {
        leftButton.classList.add("hidden");
        rightButton.classList.add("hidden");
    }
  
    setRangeMax(slides, carouselRange)
  })
}

function setRangeMax(arr, input) {
  const slidesLength = Array.from(arr).length
  let rangeMax = '0'
  if (window.innerWidth < 992 && window.innerWidth > 600) {
    rangeMax = (slidesLength - 2).toString()
  } else if (window.innerWidth < 600) {
    rangeMax = (slidesLength - 1).toString()
  }

  input.max = rangeMax

  setRangeThumbWidth(rangeMax, input)
}

function setRangeThumbWidth(max, input) {
  const width = 200 / +max
  input.style.setProperty('--thumb-size', width.toString() + "px");

  if(max === "1"){
    input.style.setProperty('--thumb-size', "100px");
  }
}