import Glide from '@glidejs/glide'

const logoCarousels = document.querySelectorAll(".logo-carousel__inner")

const logoCarouselsArray = Array.from(logoCarousels) || []

if(logoCarousels){

  logoCarouselsArray.forEach(item => {

    const leftButton = item.querySelector('.logo-carousel__arrow-left');
    const rightButton = item.querySelector('.logo-carousel__arrow-right');

    if (leftButton.classList.contains('glide__arrow--disabled') && rightButton.classList.contains('glide__arrow--disabled')) {
        leftButton.classList.add("hidden");
        rightButton.classList.add("hidden");
    }

    var glide = new Glide(item, {
      type: 'slider',
      perView: 6,
      gap: 40,
      bound: true,
      rewind: true,
      breakpoints: {
        1300: {
          perView: 5,
        },
        1100: {
          perView: 4,
        },
        450: {
          perView: 1.2,
        },
      }
    })
    
    glide.mount()

    const logos = item.querySelectorAll(".logo-carousel__item");
    const logoCount = Array.from(logos).length;
    if (logoCount <= glide.settings.perView) {
      const leftButton = item.querySelector('.logo-carousel__arrow-left');
      const rightButton = item.querySelector('.logo-carousel__arrow-right');
      leftButton.classList.add('hidden');
      rightButton.classList.add('hidden');
    }
  })
}