import Glide from '@glidejs/glide'

const mediaCarousels = document.querySelectorAll(".media-carousel__inner")

const mediaCarouselsArray = Array.from(mediaCarousels) || []

if(mediaCarousels){

  const mediaItems = document.querySelectorAll(".media-carousel__item");
  const mediaCount = Array.from(mediaItems).length;
    mediaCarouselsArray.forEach(item => {
      var glide = new Glide(item, {
        type: 'carousel',
        perView: 2.78,
        focusAt: 'center',
        gap: 60,
        rewind: true,
        breakpoints: {
          1200: {
            perView: 2.3,
            gap: 30,
          },
          800: {
            perView: 1.7,
            gap: 30,
          },
          600: {
            perView: 1.4,
            gap: 30,
          },
          480: {
            perView: 1,
            gap: 30,
          },
        }
      })
      
      if (mediaCount < 4) {
        const leftArrow = document.querySelector('.media-carousel__arrow-left');
        const rightArrow = document.querySelector('.media-carousel__arrow-right');
        leftArrow.classList.add('hidden');
        rightArrow.classList.add('hidden')
        glide.update({
          swipeThreshold: false, 
          dragThreshold: false,
          focusAt: 'left',
          perView: mediaCount
        })
      }

      glide.mount()
  
      const leftButton = item.querySelector('.media-carousel__arrow-left');
      const rightButton = item.querySelector('.media-carousel__arrow-right');
  
      if (leftButton.classList.contains('glide__arrow--disabled') && rightButton.classList.contains('glide__arrow--disabled')) {
          leftButton.classList.add("hidden");
          rightButton.classList.add("hidden");
      }
    })
  }

if(document.querySelector(".media-carousel")){
  const mediaCarousels = document.querySelectorAll(".media-carousel")
  const mediaCarouselsArray = Array.from(mediaCarousels)

  mediaCarouselsArray.forEach(carousel =>{
    const playButtons = carousel.querySelectorAll(".media-carousel__button")
    const videos = carousel.querySelectorAll(".modal-video__video")
    const modals = carousel.querySelectorAll(".modal-video")

    const videosArray = Array.from(videos)
    const modalsArray = Array.from(modals)

    Array.from(playButtons).forEach((button, index) => {
      button.addEventListener('click', ()=>{
        const video = videosArray[index] as HTMLVideoElement

        const modal = modalsArray[index] as HTMLDialogElement
        
        modal?.showModal()

        video?.play()
    })
  })

  window.addEventListener('click', function(event) {
    const videosArray = Array.from(videos)

    videosArray.forEach(video =>{
      if (video && (event.target as Element).className == "modal-video") {
        closeVideoModal(video)
      }

      if(video && (event.target as Element).id == "modal-video__close")   {
        closeVideoModal(video)
      }
    
      if((event.target as Element).id == "modal-video__close-icon"){
        closeVideoModal(video)
      }
    })
  });

  function closeVideoModal(video){
      video.pause();
      video.currentTime = 0;

      modals.forEach(modal => {
        const modalElement = modal as HTMLDialogElement
        modalElement.close();
      })
  }
  })
}